import { Button, Modal, Col, Row, Container } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FaArrowUp } from 'react-icons/fa';

import logo from './assets/logo.png'; // Byt ut mot Lucas logga
import instagramIcon from './assets/instagram.png'
import mailIcon from './assets/mail.png'
import map from './assets/map.png'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const firebaseConfig = {
    apiKey: "AIzaSyAKJvi8ZZa9HALjaugtmhVtRXdd3W8_M_A",
    authDomain: "lullesfonsterputs.firebaseapp.com",
    projectId: "lullesfonsterputs",
    storageBucket: "lullesfonsterputs.appspot.com",
    messagingSenderId: "282963384948",
    appId: "1:282963384948:web:22bb8efdab627cd9bf4f21",
    measurementId: "G-F1T2TV1WC4"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const ScrollToTopButton = () => {
        const [isVisible, setIsVisible] = useState(false);

        useEffect(() => {
            // Add a scroll event listener to check if the user has scrolled down
            const handleScroll = () => {
                const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                const scrollThreshold = 100; // Adjust this value based on your design
                setIsVisible(scrollTop > scrollThreshold);
            };

            window.addEventListener('scroll', handleScroll);

            // Remove the event listener on component unmount
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);

        const scrollToTop = () => {
            // Scroll to the top of the page
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Smooth scrolling
            });
        };

        return (
            <Button
                variant="dark"
                className={`scroll-to-top-button rounded-circle ${isVisible ? 'visible' : 'hidden'}`}
                onClick={scrollToTop}
            >
                <FaArrowUp />
            </Button>
        );
    };

    const ShortenedText = ({ maxLength }) => {
        const [showFull, setShowFull] = useState(false);
        const text = "Lullens Fönsterputs är ett nystartat, ambitiöst företag i Jönköping som har ett tydligt mål - att sätta sig själva och företaget på kartan inom fönsterputsbranschen. Med en passion för att skapa skinande rena fönster och en vision om att erbjuda högkvalitativa tjänster, strävar Lullens Fönsterputs efter att bli regionens främsta leverantör av fönsterputs. Medan företaget är ungt, är teamet bakom Lullens Fönsterputs inte det minsta ovana vid att leverera service i världsklass. Genom att kombinera erfarenhet och expertis inom fönsterputs, strävar de efter att erbjuda sina kunder resultatinriktade och pålitliga lösningar. De förstår att rena fönster inte bara ger estetisk tillfredsställelse, utan också ökar ljusinsläppet och skapar en trevlig och välkomnande atmosfär både hemma och på arbetsplatsen. Lullens Fönsterputs är stolta över att erbjuda skräddarsydda tjänster som kan uppfylla specifika behov och krav. Oavsett om det gäller stora kontorsbyggnader, privata bostäder eller företagslokaler, använder de sig av moderna tekniker och kvalitetsprodukter för att säkerställa att varje fönster blir gnistrande rent. Med stor noggrannhet och professionalitet tar de sig an varje uppdrag och strävar alltid efter att överträffa sina kunders förväntningar. "

        const toggleShowFull = () => {
            setShowFull(!showFull);
        };

        return (
            <div>
                <p>
                    {showFull ? text : `${text.slice(0, maxLength)}... `}
                    <span onClick={toggleShowFull} style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline' }}>
                        {showFull ? 'Läs mindre' : 'Läs mer'}
                    </span>
                </p>
            </div>
        );
    };

    const downloadFile = () => {
        const fileName = 'Template.xlsx';

        const fileUrl = '/' + fileName;

        const link = document.createElement('a');

        link.href = fileUrl;

        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    return (
        <div>
            <Container fluid>
                <ScrollToTopButton />
                <Row className='d-flex'>
                    <Col xs={12} md={6} className='first-side d-flex flex-column justify-content-center align-items-center'>
                        <div className="text-center">
                            <img
                                src={logo}
                                alt="Logo"
                                style={{ maxWidth: '100%' }}
                                className="logo-pos"
                            />
                            <div className="mt-3 text-center">
                                <Button variant="light" href="mailto:lullens@hotmail.com" className='mb-3'>
                                    <h5>
                                        Maila mig
                                        <br />
                                        lullens@hotmail.com
                                    </h5>
                                </Button>
                                <a href="https://www.instagram.com/lullens_fonsterputs/" target="_blank" rel="noopener noreferrer" className='mx-auto d-block mt-3'>
                                    <img
                                        src={instagramIcon}
                                        alt="Logo"
                                        height="50"
                                    />
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="second-side d-flex flex-column">
                        <div className='second-side-text'>
                            <h1> Om oss</h1>
                            <ShortenedText maxLength={200} />
                        </div>
                        <div className='mx-auto text-center mt-auto'>
                            <Button variant="dark" size="lg" className="m-4" href="#prislista" >
                                <h1>
                                    Prislista
                                </h1>
                            </Button>
                            <Button variant="dark" size="lg" className="m-4" onClick={handleShowModal}>
                                <h1>
                                    Boka här
                                </h1>
                            </Button>
                            <Button variant="dark" size="lg" className="m-4" href="#salja" >
                                <h1>
                                    Sälja Fönsterputs
                                </h1>
                            </Button>

                            <h3 className='text-center'>
                                F-Skattsedel      Momspliktigt
                                <br />
                                registrerade för RUT-avdrag
                            </h3>
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex'>
                    <Col xs={12} md={4} className='d-flex flex-column flex-fill'>

                        <div className='information second-row flex-fill'>

                            <div className='opening-hours'>
                                <h3>Fönsterputs <br />
                                    Måndag-Söndag 9:00 - 17:00</h3>
                            </div>

                            <div className='map-and-text'>
                                <div className='map-container'>
                                    <img
                                        src={map}
                                        alt="Map"
                                        height="500"
                                        className='map-image'
                                    />
                                </div>

                                <div className='text-container' id="prislista">
                                    <p>
                                        Vi erbjuder fönsterputs inom Jönköpings kommun.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col xs={12} md={4} className='d-flex flex-column flex-fill'>
                        <div className='information second-row flex-fill'>
                            <h2>
                                Prislista
                            </h2>
                            <Row>
                                <Col>
                                    <h4>Exklusive moms</h4>
                                    <ul>
                                        <li>Pris per fönster (exkl. moms): 50 kr</li>
                                        <li>Pris per fönster insida och utsida (exkl. moms): 100 kr</li>

                                    </ul>
                                </Col>
                                <h4>Inklusive moms</h4>
                                <Col>
                                    <ul>
                                        <li>Pris per fönster (inkl. moms): 62,5 kr</li>
                                        <li>Pris per fönster insida och utsida (inkl. moms): 125 kr</li>

                                    </ul>
                                </Col>
                                <h4>Rut-avdrag exempel</h4>
                                <Col>
                                    <p>
                                        Antal fönster: 10 <br />
                                        Pris per fönster (inkl. moms): 62,5kr <br />

                                        Totalt pris för fönsterputs: 10 fönster x 62,5kr = 625kr <br />

                                        Därefter drar vi av 50% av arbetskostnaderna: <br />
                                        Arbetskostnader: 625kr <br />
                                        50% avdrag: 625/2 = 312.5kr <br />

                                        Totalt pris för fönsterputs efter avdrag: 625kr - 312.5kr = 312.5kr <br />
                                        Slutpriset för kund blir alltså 312.5kr efter att ha dragit av 50% av arbetskostnaderna.
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className='d-flex flex-column flex-fill'>
                        <div className=' mx-auto text-center flex-fill'>
                            <div className='information second-row'>
                                <div className='opening-hours flex-fill'>
                                    <h2>
                                        Varför gäller dessa priser?
                                    </h2>
                                    <p>
                                        Välkommen till vårt företag! Vi erbjuder fönsterputsningstjänster av hög kvalitet till konkurrenskraftiga priser.
                                        För att garantera en rättvis och transparent prissättning för våra kunder följer vi en grundtanke som baseras på 50 kr per fönster, exklusive moms.
                                        Vi är medvetna om att varje fönster är unikt och kan ha olika behov när det gäller putsning.
                                        Därför skräddarsyr vi alltid våra tjänster efter dina individuella önskemål och behov. <br />
                                        Detta gör det möjligt för oss att erbjuda en prisättning som är anpassad efter just dina fönster.
                                        För att ge dig en tydligare bild av priserna inkluderar vi moms när vi kommunicerar kostnaden per fönster.
                                        Med moms ingår priset på 62,5 kr per fönster i vår prislista.  <br />
                                        Vi förstår att det är viktigt för dig som kund att veta exakt vad du betalar för.
                                        Därför kommer vi alltid att göra en grundlig bedömning av dina fönster och deras behov innan vi ger dig en definitiv kostnadsuppgift.
                                        Detta gör att du kan känna dig trygg med att priset du betalar motsvarar den kvalitet och service du får av oss.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>


                </Row>
                <Modal show={showModal} onHide={handleCloseModal} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bokning sker genom mail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5> Inkludera följande i ditt mail (se exempel)</h5>
                        <ul>
                            <li>Postnummer: 123 45</li>
                            <li>Adress: Testgatan 1</li>
                            <li>För och efternamn: Anders Andersson</li>
                            <li>Telefonnummer: 076 123 45 67</li>
                            <li>Personnummer: 199010151234 (för RUT-Avdrag)</li>
                            <li>Antal fönster: 12 </li>
                            <li>Behövs stege?: Ja/Nej</li>
                            <li>Utvändigt/invändigt:</li>
                            <li>Datum och tid: 25 April 2024 11:00</li>
                        </ul>
                        <Button variant="light" className="m-4" href="mailto:lullens@hotmail.com?subject=Bokning Fonsterputs&body=Postnummer:%0D%0AAdress:%0D%0AFör och efternamn:%0D%0ATelefonnummer:%0D%0APersonnummer:%0D%0AAntal fönster:%0D%0ABehövs stege:%0D%0AInvändigt/Utvändigt:%0D%0ADatum och tid:">
                            <h6> Tryck här för att boka genom mail</h6>
                            <img
                                src={mailIcon}
                                alt="Mail"
                                height="30"
                            />
                        </Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <p>Vänligen vänta på bokningsbekräftelse på mail för att säkerställa att bokningen blev genomförd.<br />(Svar ges vanligtvis inom 1-2 dagar)</p>
                    </Modal.Footer>
                </Modal >


                <Row className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='mx-auto text-center' id='salja'>
                        <Button variant="success" size="lg" className="m-4" onClick={downloadFile}>
                            <h1>
                                Ladda ner mall för att börja sälja fönsterputs
                            </h1>
                        </Button>
                        <h5>
                            Upptäck en fantastisk möjlighet för skolklasser att både tjäna pengar och samtidigt lära sig om entreprenörskap och försäljning.
                            Hos oss på LULLENS FÖNSTERPUTS ger vi skolklasser möjligheten att sälja fönsterputs och tjäna hela 20% på varje fönster de säljer!
                            Vi förstår vikten av att skolor och klasser behöver extra PENGAR för att finansiera olika projekt och aktiviteter.
                            Vårt program erbjuder en spännande och engagerande sätt för eleverna att samla in pengar samtidigt som de utvecklar värdefulla kunskaper och entreprenörsanda.
                            Med våra högkvalitativa fönsterputs tjänst kan eleverna erbjuda rena och gnistrande fönster till både privatpersoner och företag i deras lokala samhälle.
                            För varje fönster som säljs får klassen hela 20% av försäljningspriset – en generös andel som kan komma väl till pass för deras projekt och aktiviteter
                        </h5>
                    </div>
                </Row>
            </Container >
        </div >
    );
}

export default App;

